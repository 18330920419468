<template lang="">
    <div>

     <v-carousel :cycle="false">
        <v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src">
          <v-container fill-height>

            <v-card height="200" class="ml-8">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <img
                        src="media/logos/logo-letter-1.png"
                        class="max-h-80px logo-sege"
                        alt=""
                      />
                   <h3 class="caption font-weight-bold text-h5 mt-2">
                            Bienvenido al Sistema de Cooperación <br/> SICOOPERAGT
                            <v-sheet class="mt-2" width="50" height="4" color='#03639c'></v-sheet>
                          </h3>
                          
                      <!-- <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/> -->
                   
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row class="white--text mx-auto d-none">
              <v-col class="white--text text-center">

                <span>
                <p class="animated fadeInUp display-3">Bienvenido al Sistema de Cooperación </p>
                </span>

                <br>

                <span>
                <p class="animated fadeInUp delay-1s display-2">SICOOPERA </p>
                </span>

                <br>

              </v-col>
            </v-row>
          </v-container>

        </v-carousel-item>
      </v-carousel>

      <div class="row mt-4 mb-2">

        <div class="col-12 col-md-12">
        <h3 class="caption font-weight-bold text-h5">
                ENLACES
                <v-sheet class="mt-2" width="50" height="4" color='#03639c'></v-sheet>
              </h3>
           <!-- <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/> -->
        </div>
    </div>

        <div class="row">
        <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            
          <router-link :to="`/consultas/proyectos/documentos`" v-slot="{ href }">
             <a :href="href" class="menu-link">
                <template>
                    <v-card
                    class="mx-auto"
                    outlined
                    height="180"
                    dark
                    style="background: rgb(20,136,194); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(20,136,194,1) 0%, rgba(20,117,166,1) 68%); "
                    >
                    <v-list-item three-line>
                        <!-- <v-list-item-avatar
                        tile
                        size="150"
                        color="grey"
                        ></v-list-item-avatar> -->

                        <v-list-item-content class="pl-4 mt-4">
                        <!--  <div class="text-overline mb-4">
                            OVERLINE
                        </div> -->
                        <v-list-item-title class="text-h5 mb-1">
                            <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Documentos <br>de proyectos </h3>
                            <v-divider></v-divider>
                            <p class="text-light font-weight-bolder line-height-xs mb-5 text-h3"><small class="text-subtitle-1">Consulta de documentos de proyectos registrados</small> </p>
                            
                            <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                            <!-- <v-btn class="mt-4" text outlined to="/consultas/proyectos/documentos">Documentos de proyectos</v-btn>
                            <v-btn class="ml-2 mt-4" text outlined to="/consultas/proyectos/fuentes-cooperantes">Fuentes cooperantes</v-btn> -->
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                        </v-list-item-content>

                    
                    </v-list-item>

                        <!-- <v-card-actions>
                        <v-btn
                            outlined
                            rounded
                            text
                        >
                            Button
                        </v-btn>
                        </v-card-actions> -->
                    </v-card>
                </template>
                </a>
            </router-link>
        </div>

        <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12 mt-2">
          <router-link :to="`/consultas/proyectos/fuentes-cooperantes`" v-slot="{ href }">
             <a :href="href" class="menu-link">
                        <template>
                            <v-card
                            class="mx-auto"
                            dark
                            height="180"
                            style="background: rgb(123,159,181); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(123,159,181,1) 0%, rgba(95,118,133,1) 68%);"
                            >
                            <v-list-item three-line>
                                <!--  <v-list-item-avatar
                                tile
                                size="150"
                                color="grey"
                                ></v-list-item-avatar> -->

                                <v-list-item-content class="pl-4 mt-4">
                                <!--  <div class="text-overline mb-4">
                                    OVERLINE
                                </div> -->
                                <v-list-item-title class="text-h5 mb-1">
                                    <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Fuentes <br>Cooperantes </h3>
                                    <v-divider></v-divider>
                                    <p class="text-light font-weight-bolder line-height-xs mb-5 text-h3"><small class="text-subtitle-1">Consulta de proyectos registrados por fuente cooperante</small> </p>
                                    <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                                    <!-- <v-btn text outlined to="/proyectos/cooperacion-descentralizada/externo/registro">Administrar proyectos</v-btn> -->
                                </v-list-item-title>
                                <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                                </v-list-item-content>

                            
                            </v-list-item>

                                <!-- <v-card-actions>
                                <v-btn
                                    outlined
                                    rounded
                                    text
                                >
                                    Button
                                </v-btn>
                                </v-card-actions> -->
                            </v-card>
                        </template>
                          </a>
            </router-link>
                    

                        
        </div><!--end: col-->

        <div class="col-xl-4 col-md-4 mt-2">

          <v-card elevation="0" class="card card-custom gutter-b card-stretch card-shadowless" height="180">
              <div class="card-body p-0 d-flex">
                  <div class="d-flex align-items-start justify-content-start flex-grow-1 p-8 card-rounded flex-grow-1 position-relative" style="background-color:#e4f6ff">
                      <div class="d-flex flex-column align-items-start flex-grow-1 h-100">
                          <div class="p-1 flex-grow-1"><h4 class="font-weight-bolder" style="color: #1488c2;">Usuario <br/>SicooperaGT<br/> </h4><!-- <p class="text-dark-50 font-weight-bold mt-3"> Pay 0$ for the First Month </p> --></div>
                          <router-link
                              :to="`/login`"
                              v-slot="{ href, navigate, isActive, isExactActive }"
                              ><a :href="href" class="btn btn-link btn-link-warning font-weight-bold text-dark-60">Iniciar sesión 
                                  <span class="svg-icon svg-icon-lg svg-icon-warning">
                                      <svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                          <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                                          <title xmlns="http://www.w3.org/2000/svg">Stockholm-icons / Navigation / Arrow-right</title>
                                          <desc xmlns="http://www.w3.org/2000/svg">Created with Sketch.</desc>
                                          <defs xmlns="http://www.w3.org/2000/svg"></defs>
                                          <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Navigation-/-Arrow-right" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                              <rect id="Rectangle" fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"></rect>
                                              <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 
                                                      C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 
                                                      L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 
                                                      L9.70710318,15.7071045 Z" id="Path-94" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "></path>
                                          </g>
                                      </svg>
                                  </span>
                              </a>
                          </router-link>
                      </div>
                      <div class="position-absolute right-0 bottom-0 mr-5 overflow-hidden"><img src="/media/bg/login_link.png" alt="" class="max-h-180px max-h-xl-240px mb-n20"></div></div></div>
            </v-card>
        </div>


        <div class="col-xl-4 d-none">
        <div class="card card-custom card-shadowless gutter-b card-stretch card-shadowless p-0">
            <ul role="tablist" class="dashboard-tabs nav nav-pills nav-danger row row-paddingless m-0 p-0 flex-column flex-sm-row">
                <li class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0">
                    <a data-toggle="pill" href="#tab_forms_widget_1" class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center">
                        <span class="nav-icon py-2 w-auto">
                            <span class="svg-icon svg-icon-3x">
                                <svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                                    <title xmlns="http://www.w3.org/2000/svg">Stockholm-icons / Home / Library</title>
                                    <desc xmlns="http://www.w3.org/2000/svg">Created with Sketch.</desc>
                                    <defs xmlns="http://www.w3.org/2000/svg"></defs>
                                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Home-/-Library" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                        <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 
                                            C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" id="Combined-Shape" fill="#000000">
                                        </path>
                                        <rect id="Rectangle-Copy-2" fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <span class="nav-text font-size-lg py-2 font-weight-bold text-center">SAAS Application</span>
                    </a>
                </li>
                
                <li class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0">
                    <a data-toggle="pill" href="#tab_forms_widget_2" class="nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center">
                        <span class="nav-icon py-2 w-auto">
                            <span class="svg-icon svg-icon-3x">
                                <svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                                    <title xmlns="http://www.w3.org/2000/svg">Stockholm-icons / Layout / Layout-4-blocks</title>
                                    <desc xmlns="http://www.w3.org/2000/svg">Created with Sketch.</desc>
                                    <defs xmlns="http://www.w3.org/2000/svg"></defs>
                                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Layout-/-Layout-4-blocks" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                        <rect id="Rectangle-7" fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                        <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z 
                                        M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 
                                        C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <span class="nav-text font-size-lg py-2 font-weight-bolder text-center">Main Categories</span>
                    </a>
                </li>
                
                <li class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0">
                    <a data-toggle="pill" href="#tab_forms_widget_3" class="nav-link active border py-10 d-flex flex-grow-1 rounded flex-column align-items-center">
                        <span class="nav-icon py-2 w-auto">
                            <span class="svg-icon svg-icon-3x">
                                <svg version="1.1" viewBox="0 0 24 24" height="24px" width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                                    <title xmlns="http://www.w3.org/2000/svg">Stockholm-icons / Media / Movie-Lane2</title>
                                    <desc xmlns="http://www.w3.org/2000/svg">Created with Sketch.</desc>
                                    <defs xmlns="http://www.w3.org/2000/svg"></defs>
                                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Media-/-Movie-Lane-#2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                        <path d="M6,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,5 C4,3.8954305 4.8954305,3 6,3 Z M5.5,5 C5.22385763,5 5,
                                        5.22385763 5,5.5 L5,6.5 C5,6.77614237 5.22385763,7 5.5,7 L6.5,7 C6.77614237,7 7,6.77614237 7,6.5 L7,5.5 C7,5.22385763 6.77614237,5 6.5,5 L5.5,5 Z M17.5,5 C17.2238576,5 17,5.22385763 17,5.5 L17,6.5 C17,6.77614237 
                                        17.2238576,7 17.5,7 L18.5,7 C18.7761424,7 19,6.77614237 19,6.5 L19,5.5 C19,5.22385763 18.7761424,5 18.5,5 L17.5,5 Z M5.5,9 C5.22385763,9 5,9.22385763 5,9.5 L5,10.5 C5,10.7761424 5.22385763,11 5.5,11 L6.5,11 
                                        C6.77614237,11 7,10.7761424 7,10.5 L7,9.5 C7,9.22385763 6.77614237,9 6.5,9 L5.5,9 Z M17.5,9 C17.2238576,9 17,9.22385763 17,9.5 L17,10.5 C17,10.7761424 17.2238576,11 17.5,11 L18.5,11 C18.7761424,11 19,10.7761424 19,10.5 L19,9.5 C19,9.22385763 
                                        18.7761424,9 18.5,9 L17.5,9 Z M5.5,13 C5.22385763,13 5,13.2238576 5,13.5 L5,14.5 C5,14.7761424 5.22385763,15 5.5,15 L6.5,15 C6.77614237,15 7,14.7761424 7,14.5 L7,13.5 C7,13.2238576 6.77614237,13 6.5,13 L5.5,13 Z M17.5,13 C17.2238576,13 17,13.2238576 
                                        17,13.5 L17,14.5 C17,14.7761424 17.2238576,15 17.5,15 L18.5,15 C18.7761424,15 19,14.7761424 19,14.5 L19,13.5 C19,13.2238576 18.7761424,13 18.5,13 L17.5,13 Z M17.5,17 
                                        C17.2238576,17 17,17.2238576 17,17.5 L17,18.5 C17,18.7761424 17.2238576,19 17.5,19 L18.5,19 C18.7761424,19 19,18.7761424 19,18.5 L19,17.5 C19,17.2238576 18.7761424,17 18.5,17 L17.5,17 Z M5.5,17 C5.22385763,17 5,17.2238576 5,17.5 L5,18.5 C5,18.7761424 
                                        5.22385763,19 5.5,19 L6.5,19 C6.77614237,19 7,18.7761424 7,18.5 L7,17.5 C7,17.2238576 6.77614237,17 6.5,17 L5.5,17 Z" id="Combined-Shape" fill="#000000" opacity="0.3">
                                        </path>
                                        <path d="M11.3521577,14.5722612 L13.9568442,12.7918113 C14.1848159,12.6359797 14.2432972,12.3248456 14.0874656,12.0968739 C14.0526941,12.0460053 14.0088196,12.002002 13.9580532,11.9670814 
                                        L11.3533667,10.1754041 C11.1258528,10.0189048 10.8145486,10.0764735 10.6580493,10.3039875 C10.6007019,10.3873574 10.5699997,10.4861652 10.5699997,10.5873545 L10.5699997,14.1594818 C10.5699997,14.4356241 10.7938573,14.6594818 11.0699997,14.6594818 
                                        C11.1706891,14.6594818 11.2690327,14.6290818 11.3521577,14.5722612 Z" id="Path-10" fill="#000000">
                                        </path>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <span class="nav-text font-size-lg py-2 font-weight-bolder text-center">Video Tutorials</span>
                    </a>
                </li>
                
                
            </ul>

<div class="tab-content m-0 p-0">
    <div id="forms_widget_tab_1" role="tabpanel" class="tab-pane active"></div>
    <div id="forms_widget_tab_2" role="tabpanel" class="tab-pane"></div>
    <div id="forms_widget_tab_3" role="tabpanel" class="tab-pane"></div>
    <div id="forms_widget_tab_4" role="tabpanel" class="tab-pane"></div>
    <div id="forms_widget_tab_6" role="tabpanel" class="tab-pane"></div>
</div></div></div>


</div>

<v-row class="d-none">
<v-col cols="12" md="8" sm="8" xs="12"> 

<template>
  <v-row align="center">
    <v-item-group
      v-model="window"
      class="shrink mr-6"
      mandatory
      
    >
      <v-item
        v-for="n in length"
        :key="n"
        v-slot="{ active, toggle }"
      >
        <div>
          <v-btn
            :input-value="active"
            icon
            @click="toggle"
          >
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </div>
      </v-item>
    </v-item-group>

    <v-col>
      <v-window
        v-model="window"
        class="elevation-1"
        vertical
      >
        <v-window-item>
          <v-card flat>
            <v-card-text>
              <v-row
                class="mb-4 mt-4"
                align="center"
              >
                <!-- <v-avatar
                  color="grey"
                  class="mr-4"
                ></v-avatar> -->
                <strong class="text-h5 pl-3"  style="color: #1488c2;">Sistema de Gestión, Ejecución y Análisis de la Cooperación Internacional</strong>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>mdi-account</v-icon>
                </v-btn> -->
              </v-row>

              <p class="text-h6">
                El Sistema Gestión, Ejecución y Análisis de la Cooperación Internacional –SICOOERA-, contiene información acumulada a la fecha, de los fondos de Cooperación Internacional mo Reembolsable 
                recibidos y ejecutados por las Instituciones Ejecutoras (Centralizadas, Descentralizadas y Autónomas). Lo cual permite conocer los niveles de ejecución de los distintos programas y proyectos, 
                así como realizar análisis de las principales tendencias de la Cooperación Internacional No Reembolsable, por medio de diferentes reportes, que permiten conocer los sectores, áreas territoriales 
                y prioridades que son atendidas y apoyadas por la CI. Este sistema facilita la vinculación de la Cooperación Internacional a las prioridades nacionales y territoriales, y promueve la eficacia de la 
                Ayuda al Desarrollo a través de la Apropiación, Alineación, Armonización, Rendición mutua de cuentas y Gestión orientada a resultados.
              </p>
            </v-card-text>
          </v-card>
        </v-window-item>

        <v-window-item>
          <v-card flat>
            <v-card-text>
              <v-row
                class="mb-4 mt-4"
                align="center"
              >
                <!-- <v-avatar
                  color="grey"
                  class="mr-4"
                ></v-avatar> -->
                <strong class="text-h5 pl-3"  style="color: #1488c2;">Legislación</strong>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>mdi-account</v-icon>
                </v-btn> -->
              </v-row>

                <p  class="text-h6">
                La Secretaría de Planificación y Programación de la Presidencia, pone a disposición el Sistema de Gestión, Ejecución y Análisis de la Cooperación Internacional (SICOOERA) para el cumplimiento 
                de lo establecido en el artículo 33 Decreto 13-2013, “Las entidades deberán registrar y mantener actualizada su información en el Sistema Nacional de Inversión Pública y el Sistema de Donaciones de 
                la Secretaría de Planificación y Programación de la Presidencia”.
              </p>

            </v-card-text>
          </v-card>
        </v-window-item>

        <v-window-item>
          <v-card flat>
            <v-card-text>
              <v-row
                class="mb-4"
                align="center"
              >
                <!-- <v-avatar
                  color="grey"
                  class="mr-4"
                ></v-avatar> -->
                <strong class="text-h5 pl-3" style="color: #1488c2;">Condiciones para la utilización del SICOOERA </strong>
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>mdi-account</v-icon>
                </v-btn> -->
              </v-row>
              <p  class="text-h6">
                Los usuarios de SICOOERA, aceptan por el sólo hecho de su registro en el sitio, que los datos e información a registrar dentro de los diferentes submódulos 
                SICOOERA es confiable y fehaciente, y que la asignación de usuario, otorga su conformidad para que la información en los diferentes submódulos, formen parte de las estadísticas 
                y reportes que se procesen y publiquen por parte de la Secretaría de Planificación y Programación de la Presidencia, sin que la misma tenga responsabilidad equívoca disponible públicamente para efectos. 
                Los usuarios no podrán realizar actos maliciosos o atentatorios contra el sitio, que de alguna manera pueda dañar, inutilizar, sobrecargar, deteriorar, impedir o limitar la utilización de todas o algunas de las funcionalidades del mismo.
              </p>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>

</v-col>
</v-row>

        <div class="row d-none">
            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12 mt-4">
            

                <template>
                    <v-card
                    class="mx-auto"
                    outlined
                    height="200"
                    dark
                    style="background: rgb(20,136,194); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(20,136,194,1) 0%, rgba(20,117,166,1) 68%); "
                    >
                    <v-list-item three-line>
                        <!-- <v-list-item-avatar
                        tile
                        size="150"
                        color="grey"
                        ></v-list-item-avatar> -->

                        <v-list-item-content class="pl-4 mt-4">
                        <!--  <div class="text-overline mb-4">
                            OVERLINE
                        </div> -->
                        <v-list-item-title class="text-h5 mb-1">
                            <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Consultas </h3>
                            <v-divider></v-divider>
                            <!-- <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">Proyectos</small> </p> -->
                            
                            <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                            <v-btn text outlined to="/consultas/proyectos/documentos">Documentos de proyectos</v-btn>
                           <!--  <v-btn class="ml-2" text outlined to="/consultas/proyectos/fuentes-cooperantes">Fuentes cooperantes</v-btn> -->
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                        </v-list-item-content>

                    
                    </v-list-item>

                        <!-- <v-card-actions>
                        <v-btn
                            outlined
                            rounded
                            text
                        >
                            Button
                        </v-btn>
                        </v-card-actions> -->
                    </v-card>
                </template>
            </div>
        </div>

        <v-container fluid>


        </v-container>
        
    </div>
</template>
<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue"
export default {
    components: {
    //  DivisorColor
    },
    data(){
        return { 
            length: 3,
            window: 0,
            items: [
              {
                src: "/media/bg/bg_manos.jpg"
              },
             /*  {
                src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
              },
              {
                src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg"
              },
              {
                src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg"
              } */
            ]
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Inicio", route: "/" },
        
        ]);

        // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
    }
    
}
</script>
<style lang="">
    
</style>